import { setSuspensionShow } from "actions/ui";
import classnames from "classnames";
import FavoritesFilled from "components/Icons/FavoritesFilled";
import FreeToAir from "components/Icons/FreeToAir";
import Kebab from "components/Icons/Kebab";
import PadlockLock from "components/Icons/PadlockLock";
import Image, { PlaceholderType } from "components/Image";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";

import style from "./style.module.css";

class EPGHeaderItem extends Component {
  subscriptionModal() {
    const { accountStatus, dispatch } = this.props;
    if (accountStatus && accountStatus.toLowerCase() === "suspended") {
      dispatch(setSuspensionShow());
    } else if (this.props.subscriptionModal) {
      this.props.subscriptionModal(this.props.channel);
    }
  }

  openMenuContext(event) {
    if (this.props.openMenuContext) {
      this.props.openMenuContext(
        this.props.channel,
        event.clientX,
        event.clientY
      );
    }
  }

  render() {
    const { channel, isConnected, fta, accountStatus } = this.props;

    const languageId = getFromLocal("languageId") ?? consts.languageId;

    return (
      <div className={style.header}>
        {isConnected === true ? (
          <>
            {(consts.padlocks === true && !channel.userHasAccess) ||
            (accountStatus && accountStatus.toLowerCase() === "suspended") ? (
              <div
                className={style.link}
                onClick={this.subscriptionModal.bind(this)}
              >
                <div
                  className={style.kebab}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.openMenuContext(event);
                  }}
                >
                  <Kebab className={style.kebabIcon} height={40} width={40} />
                  {this.props.isFavorite && (
                    <FavoritesFilled
                      className={style.favorites}
                      height={11}
                      width={12}
                    />
                  )}
                </div>
                <Image
                  rootClassName={style.img}
                  height={45}
                  src={channel.getImage(consts.image_hostname, {
                    languageId: languageId,
                    width: 171,
                    height: 171,
                  })}
                  width={45}
                  placeholder={PlaceholderType.TRANSPARENT}
                />
                <div className={style.padlockContainer}>
                  <div className={style.padlockIconContainer}>
                    <PadlockLock className={style.padlockLockIcon} />
                  </div>
                </div>
              </div>
            ) : (
              <Link
                className={style.link}
                to={consts.routes.playerlive.url.replace(
                  ":channelId",
                  channel.getId()
                )}
              >
                <div
                  className={style.kebab}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.openMenuContext(event);
                  }}
                >
                  <Kebab className={style.kebabIcon} height={40} width={40} />
                </div>
                {this.props.isFavorite && (
                  <FavoritesFilled
                    className={style.favorites}
                    height={11}
                    width={12}
                  />
                )}
                <Image
                  rootClassName={style.img}
                  height={45}
                  src={channel.getImage(consts.image_hostname, {
                    languageId: languageId,
                    width: 171,
                    height: 171,
                  })}
                  width={45}
                  placeholder={PlaceholderType.TRANSPARENT}
                />
              </Link>
            )}
          </>
        ) : (
          <div
            className={style.link}
            onClick={this.subscriptionModal.bind(this)}
          >
            <div
              className={style.kebab}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                this.openMenuContext(event);
              }}
            >
              <Kebab className={style.kebabIcon} height={40} width={40} />
            </div>

            <Image
              rootClassName={style.img}
              height={45}
              src={channel.getImage(consts.image_hostname, {
                languageId: languageId,
                width: 171,
                height: 171,
              })}
              width={45}
              placeholder={PlaceholderType.TRANSPARENT}
            />
            {fta && (
              <div className={classnames(style.ftaIconContainer)}>
                <FreeToAir className={style.fta} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect((state, _props) => {
    return {
      accountStatus: state.account.user?.data?.status,
    };
  })
)(EPGHeaderItem);
